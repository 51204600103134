<template>
  <div>
    <v-row no-gutters>
      <v-col v-if="treeview" class="col-md-3 col-1">
        <v-btn
          @click="treeSideBar = !treeSideBar"
          icon
          v-if="$vuetify.breakpoint.smAndDown"
          ><v-icon>mdi-menu</v-icon></v-btn
        >
        <div class="tree-view__wrapper pt-3" :class="`h-${type}`">
          <v-navigation-drawer
            v-model="treeSideBar"
            :permanent="!$vuetify.breakpoint.smAndDown"
            class="w-100"
            hide-overlay
            touchless
            style="
              top: 35px;
              z-index: 3;
              box-shadow: none;
              border: none !important;
            "
            :absolute="$vuetify.breakpoint.smAndDown"
          >
            <v-treeview
              :dense="!isLarge"
              activatable
              :class="{ 'tree-view-large': isLarge }"
              hoverable
              return-object
              color="success"
              :load-children="getItems"
              ref="treeReference"
              :items="categoryList"
              :open.sync="open"
              :active.sync="active"
              selected-color="success"
              selection-type="independent"
            >
              <template v-slot:prepend="{ item }">
                <v-icon v-if="item.icon == 'mdi-file-document'" dense x-small style="transform: scale(0.5);" left>
                  {{ `${item.icon2}` }}
                </v-icon>
                <v-icon dense>
                  {{ `${item.icon}` }}
                </v-icon>
              </template>
              <template v-slot:label="{ item }">
                <div
                  class="d-flex justify-content-space-between align-center"
                  no-gutters
                >
                <span class="text-truncate" v-if="item.id == 0">{{ $t(`${item.name}`) }}</span>
                  <span class="text-truncate" v-if="item.id != 0">{{ item.name }}</span>
                  <v-slide-x-reverse-transition>
                    <v-menu
                      offset-y
                      v-if="
                        permissions.can_edit_documents ||
                        permissions.can_add_documents
                      "
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="cursor-pointer"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          small
                          v-show="(item.uuid_parent || item.id == 0) && activedLast == item.id"
                        >
                          <v-icon color="grey lighten-1" dense>
                            mdi-dots-vertical
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0" dense>
                        <v-list-item
                          @click="categoryModal(item.uuid, false)"
                          link
                          v-if="
                            item.id == 0 ||
                            (item.uuid_parent &&
                              item.uuid_parent ==
                                '00000000-0000-0000-0000-000000000000')
                          "
                        >
                          <v-row no-gutters>
                            <div class="mr-2">
                              {{ $t("documentation.create_category") }}
                            </div>
                            <v-spacer />
                            <div class="ml-auto">
                              <v-icon>mdi-folder-plus-outline</v-icon>
                            </div>
                          </v-row>
                        </v-list-item>
                        <v-list-item @click="openModal(item, false)" link>
                          <v-row no-gutters>
                            <div>{{ $t("documentation.create_document") }}</div>
                            <v-spacer />
                            <div class="ml-auto">
                              <v-icon>mdi-file-document-plus-outline</v-icon>
                            </div>
                          </v-row>
                        </v-list-item>
                        <v-list-item
                          @click="categoryModal(item, true)"
                          link
                          v-if="item.id != 0"
                        >
                          <v-row no-gutters>
                            <div>{{ $t("form.edit") }}</div>
                            <v-spacer />
                            <div class="ml-auto">
                              <v-icon>mdi-pencil</v-icon>
                            </div>
                          </v-row>
                        </v-list-item>
                        <v-list-item
                          @click="onDelete(item)"
                          link
                          v-if="item.id != 0"
                        >
                          <v-row no-gutters>
                            <div>{{ $t("btn.delete") }}</div>
                            <v-spacer />
                            <div class="ml-auto">
                              <v-icon>mdi-delete-outline</v-icon>
                            </div>
                          </v-row>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-slide-x-reverse-transition>
                </div>
              </template>
            </v-treeview>

            <div class="text--secondary text-caption pl-10" v-if="messageError">
              {{ messageError }}
            </div>
          </v-navigation-drawer>
        </div>
      </v-col>
      <v-divider
        v-if="treeview && !$vuetify.breakpoint.smAndDown"
        :class="`h-${type}`"
        vertical
      ></v-divider>
      <v-col class="pa-5 tree-view__wrapper" :class="`h-${type}`"  style="position: relative"  @scroll="onScroll">
        <documents-view ref="view"
          :selected="selected"
          :docAll="docAll"
          :selectedCategory="selectedCategory"
          :selectedDoc="selectedDoc"
          @open_modal="openModal"
          @set_active="setActive"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import DocumentsView from "./DocumentsView.vue";
import user from "@/mixins/user";
import DocumentsService from "@/services/request/documents/documentsService";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import loader from "@/mixins/loader";
import SizeUi from "@/mixins/SizeUi";

export default {
  components: {
    DocumentsView,
  },
  props: {
    documents: {
      require: true,
    },
    type: {
      require: true,
    },
    categories: {
      require: true,
    },
    items: {
      require: true,
    },
    opened: {
      require: true,
    },
    actived: {
      require: true,
    },
    selectedDoc: {
      require: false,
    },
  },
  mixins: [user, notifications, loader, SizeUi],
  data: () => ({
    active: [],
    activedNew: [],
    activedLast: '',
    treeSideBar: true,
    open: [],
    docAll: true,
    selectedCategory: null,
    selectedItem: null,
    menu: false,
    edit: false,
    treeview: true,
    countOpened: 1,
    selected: [],
    documentsModal: false,
    messageError: "",
    categoryList: [
      {
        id: 0,
        type: "category",
        icon: "mdi-folder-multiple",
        name: "documentation.documentation",
        children: [],
      },
    ],
  }),
  mounted() {
    this.categoryList[0].children = _.cloneDeep(this.items);
    this.activedNew = this.actived;

    if (this.$route.params.uuid != "all" && this.$route.params.uuid) {
      this.docAll = false;
      this.treeview = false;
      this.getOneDocument();
    } else if (this.selectedDoc.length) {
      this.open = [this.categoryList[0]];

      this.selected = this.selectedDoc;
    } else {
      if (!this.items.length) {
        this.messageError = this.$t("table.noData");
      }

      if (!this.opened.length) {
        this.open = [this.categoryList[0]];
        this.active = this.activedNew;
      } else {
        this.open = [this.categoryList[0]];
        this.active = this.activedNew;

        this.categoryList[0].children.forEach((child) => {
          this.opened.forEach((open) => {
            if (open.id == child.id && !child.children.length) {
              this.getItems(child, true);
            }
          });
        });
      }
    }
  },
  beforeDestroy() {
    this.$emit("destroy", this.open, this.active);
  },
  methods: {
    setActive(item) {
      if (typeof item === "string") {
        this.categoryList[0].children.forEach((category) => {
          if (
            category.id == item &&
            category.uuid_parent == "00000000-0000-0000-0000-000000000000"
          ) {
            this.active = [category];
          }
        });
      } else this.active = [item];
    },
    onDelete(item) {
      EventBus.$emit("delete-click", item);
    },
    openModal(item, edit) {
      EventBus.$emit("open-doc-modal", item, edit, this.categoryList);
    },
    closeModal() {
      this.documentsModal = false;
      this.selectedItem = null;
      this.edit = false;
    },
    categoryModal(item, edit) {
      EventBus.$emit("edit-item", item, edit);
    },
    async getItems(item, check = false) {
      if (item.id == 0) {
        item.children = _.cloneDeep(this.items);
      } else {
        const docs = await this.getDocuments(item);

        let category = [];
        if (item.uuid_parent == "00000000-0000-0000-0000-000000000000") {
          category = await this.getCategories(item);
        }

        const items = category.concat(docs);

        if (item.children.length != items.length) {
          item.children.push(...items);
        }

        this.active = this.activedNew;

        if (check) {
          const found = _.findIndex(this.open, item);

          if (found == -1 && item.type == "category") {
            this.open.push(item);
            this.countOpened += 1;

            if (this.countOpened == this.opened.length && items.length) {
              this.setLoading(false);

              this.active = this.activedNew;
            }

            item.children.forEach((child2) => {
              this.opened.forEach((open) => {
                if (child2.id == open.id) {
                  this.getItems(child2, true);
                }
              });
            });
          }
        }
      }
    },
    async getOneDocument() {
      try {
        await DocumentsService.getOneDocument(this.$route.params.uuid)
          .then((res) => {
            const item = this.getData(res[0], "doc");
            this.selected.push(item);
          })
          .catch(() => {
            this.selected = [];
          });
      } catch (e) {
        this.selected = [];
      }
    },
    async getDocuments(item) {
      try {
        const docs = await DocumentsService.getDocumentsList(item.uuid).then(
          (res) => {
            const docs = [];
            res.forEach((doc) => {
              doc.parent_name = item.name;
              docs.push(this.getData(doc, "doc"));
              if (this.actived.length && doc.uuid == this.actived[0].id) {
                this.activedNew = [];
                this.activedNew.push(this.getData(doc, "doc"));
              }
            });
            return docs;
          }
        );
        return docs;
      } catch (e) {
        console.log(e)
      }
    },
    onScroll(e) {
      this.$refs.view.onScroll(e)
    },
    async getCategories(item) {
      try {
        const categories = await DocumentsService.getCategoriesList(
          item.uuid
        ).then((res) => {
          const categories = [];
          res.forEach((category) => {
            categories.push(this.getData(category, "category"));
          });
          return categories;
        });
        return categories;
      } catch (e) {
        console.log(e)
      }
    },
    getData(item, type) {
      item.id = item.uuid;
      if (type == "category") {
        item.icon = "mdi-folder";
        item.children = [];
      } else {
        item.name = item.title;
        item.icon = "mdi-file-document";
        item.icon2 = "mdi-checkbox-blank-circle"
      }
      item.type = type;
      return item;
    },
    checkActive(value) {
      if (value[0].type == "category") {
        this.selected = value[0].children.filter((child) => {
          return child.type != "category";
        });
      } else {
        this.selected = value;
      }
    },
  },
  watch: {
    open: {
      handler(value) {
        if (value.length > 1) {
          localStorage.opened = JSON.stringify(value);
        }
      },
    },
    active: {
      handler(value) {
        if (value[0] && value[0].type == "category") {
          this.activedLast = value[0].id;
          if (this.$route.params.uuid) {
            if (this.type == "landing") {
              this.$router
                .push(
                  this.$localize({
                    name: "landing-documents",
                    params: { uuid: "all" },
                  })
                )
                .catch(() => {});
            } else {
              this.$router
                .push(
                  this.$localize({ name: "documents", params: { uuid: "all" } })
                )
                .catch(() => {});
            }
          }
          if (!value[0].children.length && this.activedNew[0] != value[0]) {
            this.activedNew = [value[0]];
            this.selected = value[0];
            this.getItems(value[0], true);
          }
          // if (value[0].uuid_parent != "00000000-0000-0000-0000-000000000000") {
          //   this.selectedCategory = false;
          //   this.selected = value[0].children.filter((child) => {
          //     return child.type != "category";
          //   });
          // } else {
          //   this.selectedCategory = true;
          //   this.selected = value[0];
          // }

          this.selectedCategory = true;
          this.selected = value[0];
        } else {
          this.activedLast = 1;
          if (value[0]) {
            this.selectedCategory = false;
            if (this.$route.params.uuid) {
              if (this.type == "landing") {
                this.$router
                  .push(
                    this.$localize({
                      name: "landing-documents",
                      params: { uuid: value[0].uuid },
                    })
                  )
                  .catch(() => {});
              } else {
                this.$router
                  .push(
                    this.$localize({
                      name: "documents",
                      params: { uuid: value[0].uuid },
                    })
                  )
                  .catch(() => {});
              }
            }
          }
          this.selected = value;
        }
        if (value.length) {
          localStorage.actived = JSON.stringify(value);
        }
      },
    },
  },
};
</script>

<style lang="scss">
.tree-view-large {
  font-size: 17px;
}
.tree-view__wrapper {
  overflow-y: scroll;
}
.h {
  &-landing {
    max-height: 100vh !important;
    min-height: calc(100vh - 56px) !important;
    height: calc(100vh - 56px) !important;
  }
  &-panel {
    max-height: 90vh !important;
    min-height: 90vh !important;
    height: 90vh !important;
  }
}
</style>
