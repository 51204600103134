<template>
  <div>
    <v-card class="docs_wrapper" :class="`h-${type}`">
      <documents-tree
        :type="type"
        :items="items"
        :documents="documents"
        :selectedDoc="selectedDoc"
        :categories="categories"
        v-if="loaded"
        @destroy="destroyedTree"
        :opened="opened"
        :actived="actived"
      />
    </v-card>
    <categories-modal
      v-if="categoriesModal"
      :item="choosenCategory"
      :edit="editCategory"
      :categoryList="items"
      @close_modal="closeModal()"
    />
    <documents-modal
      v-if="documentsModal"
      :item="selectedDocument"
      :visible="documentsModal"
      :edit="editDoc"
      @close_modal="documentsModal = false"
      :categoryList="categoryList"
    />
    <confirm-dialog
      :visible="confirmDelete.visible"
      :data="confirmDelete.data"
      @close="confirmDelete.visible = false"
      @onConfirm="onDeleteData"
      @onCancel="confirmDelete.visible = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>{{ confirmDelete.header }}</template>
      <template v-slot:description>
        {{ confirmDelete.description }}
      </template></confirm-dialog
    >
  </div>
</template>

<script>
import DocumentsTree from "./DocumentsTree.vue";
import CategoriesModal from "./modals/CatergoriesModal.vue";
import DocumentsService from "@/services/request/documents/documentsService";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import DocumentsModal from "./modals/DocumentsModal.vue";

// //Mixins
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import EventBus from "../../events/EventBus";
import {mapActions, mapGetters} from "vuex";
import * as actionTypes from "@/store/modules/pageLoadTime/types/actions";
import * as getterTypes from "@/store/modules/pageLoadTime/types/getters";

export default {
  components: {
    DocumentsTree,
    CategoriesModal,
    ConfirmDialog,
    DocumentsModal,
  },
  props: {
    type: {
      required: false,
      default: "panel",
    },
  },
  mixins: [loader, notifications],
  data: () => ({
    categoriesModal: false,
    categories: [],
    documents: [],
    deletedItem: {},
    items: [],
    opened: [],
    actived: [],
    loaded: true,
    confirmDelete: {
      visible: false,
      data: "",
      header: "",
      description: "",
    },
    choosenCategory: null,
    editCategory: false,
    selectedDoc: [],
    documentsModal: false,
    selectedDocument: null,
    editDoc: null,
    categoryList: [],
    loadTime: 0
  }),
  created() {
    if (performance.navigation.type == 1) {
      if (localStorage.opened) {
        this.opened = JSON.parse(localStorage.opened);
      }
      if (localStorage.active) {
        this.actived = JSON.parse(localStorage.actived);
      }

      if (this.type == "landing") {
          this.$router
            .push(
              this.$localize({ name: "landing-documents", params: { uuid: "all" } })
            )
            .catch(() => {});
        }
        else {
        this.$router
          .push(this.$localize({ name: "documents", params: { uuid: "all" } }))
          .catch(() => {});
        }
    }
  },
  async mounted() {
    EventBus.$on("to-all", (selectedDoc) => {
      this.selectedDoc = selectedDoc;
      if (this.$route.params.uuid) {
        if (this.type == "landing") {
          this.$router
            .push(
              this.$localize({ name: "landing-documents", params: { uuid: "all" } })
            )
            .catch(() => {});
        }
        else {
        this.$router
          .push(this.$localize({ name: "documents", params: { uuid: "all" } }))
          .catch(() => {});
        }
      }
      this.getCategories();
    });
    EventBus.$on("open-doc-modal", (item, edit, categoryList) => {
      this.selectedDocument = item;
      this.editDoc = edit;
      this.documentsModal = true;
      this.categoryList = categoryList;
    });
    EventBus.$on("items-changed", () => {
      if (this.$route.params.uuid) {
        this.$router
          .push(this.$localize({ name: "documents", params: { uuid: "all" } }))
          .catch(() => {});
      }
      this.getCategories();
    });
    EventBus.$on("edit-item", (item, edit) => {
      this.openModal(item, edit);
    });
    EventBus.$on("delete-click", (item) => {
      if (item.type == "category") {
        this.confirmDelete = {
          visible: true,
          data: item,
          header: this.$t("documentation.category_delete"),
          description:
            this.$t("documentation.category_delete_info") +
            item.name +
            "? " +
            this.$t("documentation.category_delete_info2"),
        };
      } else if (item.type == "doc") {
        this.confirmDelete = {
          visible: true,
          data: item,
          header: this.$t("documentation.document_delete"),
          description:
            this.$t("documentation.document_delete_info") + item.name + "?",
        };
      }
    });
    if (this.$route.params.uuid == "all" || !this.$route.params.uuid) {
      await this.getCategories();
    }
    setTimeout(() => {
      document.getElementById("scrollWrappedElement").style.overflow = "hidden"; // fix this trash
    }, 500);
    this.checkLoadTime();
  },
  methods: {
    ...mapActions('pageLoadTime', {
      setPageLoadTime: actionTypes.SET_PAGE_LOAD_TIME,
    }),
    checkLoadTime() {
      const endTime = performance.now();
      this.loadTime = endTime - this.startTime;
      this.setPageLoadTime(this.loadTime)
    },
    destroyedTree(opened, actived) {
      this.opened = opened;
      this.actived = actived;

      if (localStorage.opened && this.type != "landing") {
        this.opened = JSON.parse(localStorage.opened);
      }
      if (localStorage.actived && this.type != "landing") {
        this.actived = JSON.parse(localStorage.actived);
      }

      for (let i = 0; i < this.opened.length; i++) {
        if (
          this.opened[i].id == this.deletedItem.id ||
          this.opened[i].uuid_parent == this.deletedItem.id
        ) {
          this.opened.splice(i, 1);
        }
      }
    },
    async onDeleteData(item) {
      if (item.type == "category") {
        this.onDeleteCategory(item);
      } else if (item.type == "doc") {
        this.onDeleteDocument(item);
      } else {
        this.setErrorNotification("ERROR");
      }
    },
    async onDeleteCategory(item) {
      try {
        await DocumentsService.deleteCategory(item.uuid);
        this.setSuccessNotification("Категорію успішно видалено!");

        this.deletedItem = item;
        this.getCategories();
      } catch (e) {
        console.log(e)
      } finally {
        this.confirmDelete = {
          visible: false,
          data: "",
        };
      }
    },
    async onDeleteDocument(item) {
      try {
        await DocumentsService.deleteDocument(item.uuid);
        this.setSuccessNotification("Документ успішно видалено!");
        this.getCategories();
      } catch (e) {
        console.log(e)
      } finally {
        this.confirmDelete = {
          visible: false,
          data: "",
        };
      }
    },
    openModal(item, edit) {
      this.choosenCategory = item;
      this.editCategory = edit;
      this.categoriesModal = true;
    },
    closeModal() {
      this.categoriesModal = false;
      this.choosenCategory = null;
    },
    async getCategories() {
      this.loaded = false;
      this.items = [];
      try {
        this.setLoading(true);
        this.categories = await DocumentsService.getCategoriesList(
          "00000000-0000-0000-0000-000000000000"
        );
        this.getDocuments();
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    async getDocuments() {
      try {
        this.documents = await DocumentsService.getDocumentsList(
          "00000000-0000-0000-0000-000000000000"
        );
        this.filterCategories();
        if (!this.opened.length || this.opened.length == 1) {
          this.setLoading(false);
        }
      } catch (e) {
        this.setLoading(false);
        console.log(e)
      }
    },
    filterCategories() {
      const items = [];

      this.categories.forEach((category) => {
        items.push(this.getData(category, "category"));
      });

      this.documents.forEach((document) => {
        if (this.actived.length && this.actived[0].id == document.uuid) {
          this.actived = [];
          this.actived.push(this.getData(document, "doc"));
        }
        items.push(this.getData(document, "doc"));
      });

      this.loaded = true;
      this.items = items;
    },
    getData(item, type) {
      item.id = item.uuid;
      if (type == "category") {
        item.icon = "mdi-folder";
        item.children = [];
      } else {
        item.icon = "mdi-file-document";
        item.icon2 = "mdi-checkbox-blank-circle"
        item.name = item.title;
      }
      item.type = type;
      return item;
    },
  },
  computed: {
    ...mapGetters('pageLoadTime', {
      startTime: getterTypes.GET_START_TIME,
    }),
  }
};
</script>

<style scoped>
.docs_wrapper {
  /* height:100%; */
  min-height: 90vh !important;
}
</style>
