<template>
  <v-dialog
    content-class="cross__dialog"
    v-model="categoriesModal"
    max-width="700px"
    :persistent="!isChanged"
    @click:outside="showConfirmDialog"
  >
    <v-card>
      <div class="cross__inner">
        <v-btn
          fab
          small
          elevation="0"
          @click="showConfirmDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div
        style="height: 400px"
        class="d-flex align-center justify-center"
        v-if="loading"
      >
        <v-progress-circular
          :size="50"
          color="success"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-card-title>
          <v-row :align="stateError.length ? 'start' : 'center'">
            <v-col>
              <div v-if="edit">
                {{ $t("documentation.update_category") }}
              </div>
              <div v-if="!edit">
                {{ $t("documentation.create_category") }}
              </div>
            </v-col>
            <v-col>
              <v-select
                dense
                :label="$t('table.state')"
                :items="Object.values(states)"
                :item-text="getStatusText"
                :item-value="'id'"
                :hide-details="!stateError.length"
                :error-messages="stateError"
                solo
                v-model="category.id_state"
                class="text-caption"
                :menu-props="{ bottom: true, offsetY: true }"
                color="success"
                item-color="success"
              >
                <template v-slot:item="{ item }">
                  <div class="d-flex align-center justify-content-start">
                    <StatusCircle
                      :status-name="item.id"
                      :type="'supply'"
                      class="pb-1"
                    />
                    <div class="elispsis">
                      {{ getStatusText(item) }}
                    </div>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="d-flex align-center justify-content-start">
                    <StatusCircle
                      :status-name="item.id"
                      :type="'supply'"
                      class="pb-1"
                    />
                    <div class="elispsis">
                      {{ getStatusText(item) }}
                    </div>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-2">
          <v-select
            v-if="edit"
            dense
            outlined
            :label="$t('documentation.access_level')"
            :items="Object.values(accessLevels)"
            :item-value="'id'"
            v-model="category.lvl_access"
            :menu-props="{ bottom: true, offsetY: true }"
            color="success"
            item-color="success"
          >
            <template v-slot:item="{ item }">
              <div class="d-flex align-center justify-content-start">
                <v-icon class="mr-2">{{ item.icon }}</v-icon>
                <div>
                  {{ $t(item.name) }}
                </div>
              </div>
            </template>
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center justify-content-start">
                <v-icon class="mr-2" small>{{ item.icon }}</v-icon>
                <div>
                  {{ $t(item.name) }}
                </div>
              </div>
            </template>
          </v-select>
          <v-select
            v-if="edit"
            item-text="name"
            item-value="uuid"
            v-model="category.uuid_parent"
            :items="selectArray"
            :label="$t('documentation.parent_category')"
            outlined
            dense
            :menu-props="{ bottom: true, offsetY: true }"
            color="success"
            item-color="success"
          >
            <template v-slot:item="{ item }">
              <v-list-item
                v-if="item.uuid == '00000000-0000-0000-0000-000000000000'"
              >
                <v-list-item-title>
                  <v-icon class="mr-3">{{ item.icon }}</v-icon
                  >{{ $t(`${item.name}`) }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                class="pl-10"
                v-else-if="
                  item.uuid_parent == '00000000-0000-0000-0000-000000000000'
                "
              >
                <v-list-item-title>
                  <v-icon class="mr-3">{{ item.icon }}</v-icon
                  >{{ item.name }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item class="pl-10 ml-10" v-else>
                <v-list-item-title>
                  <v-icon class="mr-3">{{ item.icon }}</v-icon
                  >{{ item.name }}
                </v-list-item-title>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
            <template v-slot:selection="{ item }">
              <div v-if="item.uuid == '00000000-0000-0000-0000-000000000000'">
                {{ $t(`${item.name}`) }}
              </div>
              <div v-else>
                {{ item.name }}
              </div>
            </template>
          </v-select>
          <v-text-field
            v-model="category.name"
            :label="$t('documentation.category_name')"
            outlined
            :error-messages="nameError"
            dense
            @keypress="validationForTitle($event)"
            @paste="validationForTitle($event)"
            color="success"
          ></v-text-field>
          <v-textarea
            outlined
            dense
            :label="$t('form.description')"
            v-model="category.description"
            no-resize
            counter
            maxlength="255"
            rows="5"
            color="success"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col>
              <v-btn
                class="br-10 w-100 text-transform-none"
                dark
                @click="$emit('close_modal')"
                >{{ $t("btn.cancel") }}</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                v-if="!edit"
                class="success-bg text-white text-transform-none br-10 w-100"
                @click="createCategory()"
                >{{ $t("btn.save") }}</v-btn
              >
              <v-btn
                v-if="edit"
                :disabled="isChanged"
                block
                class="success-bg text-white text-transform-none br-10 w-100"
                @click="updateCategory()"
                >{{ $t("btn.save") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </div>
    </v-card>
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onClose"
    />
  </v-dialog>
</template>

<script>
import DocumentsService from "@/services/request/documents/documentsService";
import EventBus from "@/events/EventBus";
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";
import language from "@/mixins/language";
import DeportService from "@/services/request/depot/depotService";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import {validationForTitle} from "@/mixins/helpers";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";

export default {
  components: {
    StatusCircle,
    ConfirmLeftDialog
  },
  props: {
    item: {
      require: true,
    },
    categoryList: {
      require: false,
    },
    edit: {
      require: true,
    },
  },
  mixins: [validationMixin, language, notifications],
  data: () => ({
    visibleConfirm: false,
    categoriesModal: true,
    loading: false,
    selectArray: [],
    states: [],
    category: {
      id_state: "1400",
      description: "",
    },
    accessLevels: [
      {
        name: "btn.all",
        icon: "mdi-account",
        id: "1000",
      },
      {
        name: "menu.owners",
        icon: "mdi-account-cowboy-hat",
        id: "1",
      },
      {
        name: "menu.admins",
        icon: "mdi-account-star",
        id: "10",
      },
      {
        name: "menu.managers",
        icon: "mdi-account-group",
        id: "20",
      },
      {
        name: "menu.employees",
        icon: "mdi-account-hard-hat",
        id: "30",
      },
      {
        name: "menu.crm",
        icon: "mdi-tooltip-account",
        id: "50",
      },
      {
        name: "menu.clients",
        icon: "mdi-clipboard-account",
        id: "40",
      },
    ],
  }),
  validations: {
    category: {
      name: {
        required,
      },
      description: {
        maxLength: maxLength(255),
      },
      id_state: {
        required,
      },
    },
  },
  mounted() {
    this.getStates();
    if (this.edit) {
      this.category = { ...this.item };
      this.getSelect();
    }
  },
  watch: {
    categoriesModal: {
      handler() {
        this.$emit("close_modal");
      },
    },
  },
  methods: {
    validationForTitle,
    onClose(){
      this.$emit('close_modal')
    },
    showConfirmDialog() {
      if (!this.isChanged) {
        this.visibleConfirm = true;
      } else {
        this.onClose();
      }
    },
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    async getStates() {
      try {
        this.loading = true;

        await DeportService.getAllStates({
          type: "CATEGORIES",
        }).then((res) => {
          this.states = res;
          this.loading = false;
        });
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    getSelect() {
      const selectArray = [];
      selectArray.push({
        name: "documentation.documentation",
        uuid: "00000000-0000-0000-0000-000000000000",
        icon: "mdi-folder-multiple",
      });
      this.categoryList.forEach((category) => {
        if (
          category.type == "category" &&
          category.uuid != this.category.uuid
        ) {
          selectArray.push(category);
        }
      });

      this.selectArray = selectArray;
    },
    async createCategory() {
      this.$v.category.$touch();
      if (!this.$v.category.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          if (this.item) {
            formData.append("uuid_parent", this.item);
          }
          formData.append("name", this.category.name);
          formData.append("description", this.category.description);
          formData.append("id_state", this.category.id_state);
          await DocumentsService.createCategory(formData);
          this.loading = false;
          this.categoriesModal = false;
          EventBus.$emit("items-changed");
        } catch (e) {
          console.log(e)
          this.loading = false;
        }
      }
    },
    async updateCategory() {
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("uuid", this.category.uuid);
        const changedData = this.$getChangedData(this.category, this.item);
        changedData.forEach((data) => {
          formData.append(data.field, data.value);
        });
        await DocumentsService.updateCategory(formData);
        this.loading = false;
        this.categoriesModal = false;
        this.setSuccessNotification(this.$t('profile.successfully_updated'));

        EventBus.$emit("items-changed");
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    isChanged() {
      if (!_.isEqual(this.category, this.item)) return false;
      else return true;
    },
    nameError() {
      const errors = [];
      if (!this.$v.category.name.$dirty) {
        return errors;
      }
      !this.$v.category.name.required &&
        errors.push(
          this.language.isRequired(this.$t("documentation.category_name"))
        );
      return errors;
    },
    stateError() {
      const errors = [];
      if (!this.$v.category.id_state.$dirty) {
        return errors;
      }
      !this.$v.category.id_state.required &&
        errors.push(this.language.isRequired("Статус"));
      return errors;
    },
  },
};
</script>
