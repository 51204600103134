<template>
  <div>
    <v-navigation-drawer
      v-if="
        !selectedCategory &&
        selected.length &&
        tags.length &&
        !$vuetify.breakpoint.smAndDown
      "
      permanent
      right
      v-model="navigationDrawer"
      fixed
      width="300px"
      class="nav-drawer"
    >
      <div class="ml-5">
        {{$t('documentation.navigation')}}
        <dl class="navigation ml-2" v-for="(tag, index) in tags" :key="index">
          <dd
            @click="toTag(tag, index)"
            :class="{ active: activeElement == index }"
          >
            {{ tag.innerText }}
          </dd>
        </dl>
      </div>
    </v-navigation-drawer>
    <div
      :class="{
        'on-mobile': $vuetify.breakpoint.smAndDown,
      }"
      class="search-card"
    >
      <v-scroll-x-reverse-transition>
        <v-card content-class="cross__dialog" v-if="showSearch">
          <v-icon small style="position: absolute; right: 0" @click="onSearch()"
            >mdi-close</v-icon
          >
          <v-row align="center" no-gutters class="pa-4">
            <v-text-field
              color="grey"
              hide-details
              dense
              :label="$t('form.search')"
              v-model="searchQuery"
              clearable
            >
            </v-text-field>
            <div class="mx-2 text-caption">
              {{ currentIndex + 1 }}/{{ this.results.length }}
            </div>
            <v-btn small @click="next()" icon>
              <v-icon small> mdi-arrow-down</v-icon>
            </v-btn>
            <v-btn small @click="prev()" icon>
              <v-icon small> mdi-arrow-up</v-icon>
            </v-btn>
          </v-row>
        </v-card>
      </v-scroll-x-reverse-transition>
    </div>
    <div
      :class="{
        'docs-view-navigation':
          !selectedCategory &&
          selected.length &&
          tags.length &&
          !$vuetify.breakpoint.smAndDown,
      }"
    >
      <v-row
        class="mb-4 mt-5 mt-sm-5 mt-md-0 text-h4"
        no-gutters
        justify="space-between"
        align="center"
      >
        <div
          @click="redirectToAll()"
          v-if="!docAll"
          class="text-caption"
          style="cursor: pointer"
        >
          <v-row no-gutters align="center">
            <v-icon small>mdi-arrow-left</v-icon>
            Переглянути всі документи
          </v-row>
        </div>
        <div
          v-if="selected.length && !selectedCategory"
          class="title-view"
          @click="$emit('set_active', selected[0].uuid_category)"
        >
          {{ selected[0].parent_name }}
        </div>
        <div v-if="(selected || selected.length) && selectedCategory">
          {{ selected.name }}
        </div>
      </v-row>
      <div
        v-if="
          !selectedCategory &&
          selected.length &&
          tags.length &&
          $vuetify.breakpoint.smAndDown
        "
      >
        <dl class="navigation ml-2" v-for="(tag, index) in tags" :key="index">
          <dd
            @click="toTag(tag, index)"
            :class="{ active: activeElement == index }"
          >
            {{ tag.innerText }}
          </dd>
        </dl>
      </div>
      <v-slide-x-transition
        group
        mode="out-in"
        v-if="selected && !selectedCategory"
      >
        <v-card
          elevation="0"
          class="mb-3"
          v-for="(selectedItem, key) in selected"
          :key="key"
        >
          <v-card-title>
            <v-row justify="space-between" no-gutters>
              <div :class="{ 'text-h5 font-weight-medium': isLarge }">
                {{ selectedItem.name }}
              </div>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :large="isLarge"
                      icon
                      @click="onSearch()"
                      v-on="on"
                      v-bind="attrs"
                      color="grey"
                    >
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn> </template
                  >{{$t('form.search')}}</v-tooltip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="printDocument(selectedItem)"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      color="grey"
                    >
                      <v-icon :large="isLarge">mdi-printer</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("documentation.print_doc") }}</v-tooltip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="copyToCLipboard(selectedItem)"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                    >
                      <v-icon :large="isLarge">mdi-link</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("documentation.copy_doc") }}</v-tooltip
                >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :large="isLarge"
                      v-if="
                        permissions.can_edit_documents ||
                        permissions.can_add_documents
                      "
                      @click="$emit('open_modal', selectedItem, true)"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      color="success"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  {{ $t("form.edit") }}
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :large="isLarge"
                      v-if="
                        permissions.can_edit_documents ||
                        permissions.can_add_documents
                      "
                      @click="onDelete(selectedItem)"
                      icon
                      v-on="on"
                      v-bind="attrs"
                      color="error"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn> </template
                  >{{ $t("btn.delete") }}</v-tooltip
                >
              </div>
            </v-row>
          </v-card-title>
          <v-card-text
            class="text-justify"
            :style="
              'margin-bottom: calc(100vh - ' + bottomHeight + 'px) !important'
            "
          >
            <v-divider v-if="selectedItem.body.length" />
            <div id="searchText" class="my-4" v-html="selectedItem.body"></div>
            <v-divider v-if="selectedItem.body.length" />
            <v-row
              no-gutters
              v-if="permissions.can_view_document_author"
              :class="{ 'text-body-1 mt-2': isLarge }"
            >
              <v-spacer />
              <div>{{ $t("documentation.author") }}:&nbsp;</div>
              <div class="font-weight-bold">
                {{ selectedItem.user_details.name }}&nbsp;{{
                  selectedItem.user_details.surname
                }}
              </div>
            </v-row>
            <v-row
              :class="!isLarge ? 'text-caption mt-1' : 'text-body-2 mt-2'"
              no-gutters
            >
              <v-spacer />
              <div>{{ $t("documentation.version") }}:&nbsp;</div>
              <div>
                {{ selectedItem.version_major }}.{{
                  selectedItem.version_minor
                }}
              </div>
            </v-row>
          </v-card-text>
        </v-card>
      </v-slide-x-transition>

      <v-slide-x-transition
        mode="out-in"
        group
        v-if="selected && selectedCategory && selected.id != 0"
      >
        <v-row
          align="center"
          @click="$emit('set_active', child)"
          style="cursor: pointer"
          class="mt-3 ml-5"
          no-gutters
          v-for="(child, index) in selected.children"
          :key="index"
        >
          <div v-if="child.type == 'category'">
            <v-icon>mdi-play</v-icon
            ><span class="text-body-1 text-decoration-underline">{{
              child.name
            }}</span>
          </div>
        </v-row>
      </v-slide-x-transition>
    </div>
    <print-modal
      :visible="printModal"
      :selectedItem="selectedItem"
      @close_print="printModal = false"
      v-if="printModal"
    />
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";
import user from "@/mixins/user";
import notifications from "../../mixins/notifications";
import SizeUi from "@/mixins/SizeUi";
import printModal from "./modals/printModal.vue";
import Mark from "mark.js";

export default {
  components: {
    printModal,
  },
  data: () => ({
    printModal: false,
    selectedItem: {},
    showSearch: false,
    searchQuery: "",
    results: [],
    currentIndex: -1,
    navigationDrawer: false,
    activeElement: -1,
    tags: [],
    bottomHeight: "100vh",
  }),
  props: {
    selected: {
      require: true,
    },
    selectedCategory: {
      require: true,
    },
    docAll: {
      require: false,
    },
    selectedDoc: {
      require: false,
    },
  },
  mixins: [user, notifications, SizeUi],
  methods: {
    nextAll(elem) {
      const content = document.getElementById("searchText").children;
      let next = false;
      return [].filter.call(content, function (child) {
        if (child === elem) next = true;
        return next;
      });
    },

    getNavigation() {
      if (!this.tags.length) {
        this.tags = [];
        const content = document.getElementById("searchText");
        const h1 = content.getElementsByTagName("h1");
        h1.forEach((el, index) => {
          el.className += "heading";
          el.id = "heading-1-" + index;
        });
        const h2 = content.getElementsByTagName("h2");
        h2.forEach((el, index) => {
          el.className += "heading";
          el.id = "heading-2-" + index;
        });
        const h3 = content.getElementsByTagName("h3");
        h3.forEach((el, index) => {
          el.className += "heading";
          el.id = "heading-3-" + index;
        });

        this.tags = content.getElementsByClassName("heading");

        const lastArray = this.nextAll(this.tags[this.tags.length - 1]);

        let height = 200;
        lastArray.forEach((el) => {
          height += el.clientHeight;
          height +=
            el.style.marginTop ||
            parseInt(window.getComputedStyle(el).marginTop, 10);
          height +=
            el.style.marginBottom ||
            parseInt(window.getComputedStyle(el).marginBottom, 10);
        });

        if (height != 200 && height < window.innerHeight && this.tags.length) {
          this.bottomHeight = height;
        } else {
          this.bottomHeight = "100vh";
        }

      }
    },
    toTag(tag, index) {
      document.getElementById(tag.id).scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      // //this.activeElement = index
    },
    onScroll(e) {
      this.tags.forEach((tag, index) => {
        const sectionTop = tag.offsetTop;
        const height = -25;
        // && sectionTop + tag.offsetHeight > e.target.scrollTop + height
        if (sectionTop <= e.target.scrollTop + height) {
          this.activeElement = index;
        }
      });
    },
    onSearch() {
      this.showSearch = !this.showSearch;

      if (!this.showSearch) {
        this.searchQuery = "";
      }
    },
    printDocument(item) {
      this.selectedItem = item;
      this.printModal = true;
    },
    async copyToCLipboard(item) {
      try {
        await navigator.clipboard.writeText(
          window.location.origin +
            "/" +
            this.$route.params.lang +
            "/panel/documents/" +
            item.uuid
        );
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    onDelete(selectedItem) {
      EventBus.$emit("delete-click", selectedItem);
    },
    redirectToAll() {
      localStorage.removeItem("actived");
      localStorage.removeItem("opened");
      EventBus.$emit("to-all", this.selected);
    },
    next() {
      if (this.results.length) {
        this.currentIndex += 1;

        if (this.currentIndex > this.results.length - 1) {
          this.currentIndex = this.results.length - 1;
        }

        this.jumpTo();
      }
    },
    prev() {
      if (this.results.length) {
        this.currentIndex -= 1;

        if (this.currentIndex < 0) {
          this.currentIndex = 0;
        }

        this.jumpTo();
      }
    },
    jumpTo() {
      const current = this.results[this.currentIndex];

      this.results.forEach((result) => {
        result.classList.remove("current");
      });

      current.className = "current";
      current.id = this.currentIndex;

      document.getElementById(current.id).scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    },
  },
  watch: {
    searchQuery: {
      handler(value) {
        const content = document.getElementById("searchText");
        const instance = new Mark(content);
        if (!value) {
          instance.unmark();
          this.currentIndex = -1;
        } else {
          instance.unmark({
            done: () => {
              instance.mark(value, {
                done: () => {
                  this.results = content.getElementsByTagName("mark");
                  this.currentIndex = -1;
                  this.next();
                },
              });
            },
          });
        }
      },
    },
    selected: {
      handler(value) {
        if (value && value[0] && !this.selectedCategory) {
          this.$nextTick(() => {
            this.tags = []
            document.title =
              "NewSend - Ваш Онлайн Склад - Документація - " +
              this.selected[0].name;

            this.activeElement = -1;
            this.getNavigation();
          });
        } else {
          document.title = "NewSend - Ваш Онлайн Склад - Документація";
        }
      },
    },
  },
};
</script>

<style lang="scss">
@import "./quilStyles.scss";

.title-view {
  cursor: pointer;
}
.title-view:hover {
  color: #4d4d4d;
}
mark {
  background: #ffea00;
}

mark.current {
  background: #ffab40;
}

.docs-view-navigation {
  margin-right: 300px;
}

dl {
  width: 100%;
  list-style: none !important;
  overflow: hidden;
  white-space: nowrap;
}
dd {
  list-style-type: none;
  width: 100% !important;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  padding: 5px 10px;
  border-left: 2.5px solid #e5e5e5;
  color: rgb(126, 126, 126);
  cursor: pointer;
  font-size: 14px;
  &:hover {
    color: rgb(77, 77, 77);
  }
  &.active {
    border-left: 2.5px solid #4caf50;
    color: #4caf50;
  }
}
.search-card {
  position: fixed;
  z-index: 20;
  right: 340px;
  &.on-mobile {
    right: 40px;
  }
}
.nav-drawer {
  z-index: 2 !important;
  top: 90px !important;
  height: calc(100% - 120px) !important;
  right: 40px !important;
}
</style>
