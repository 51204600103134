<template>
  <v-dialog
    scrollable
    :retain-focus="false"
    content-class="cross__dialog"
    v-model="visibility"
    max-width="1100px"
  >
    <v-card :loading="loading" :disabled="loading">
      <div class="cross__inner">
        <v-btn
          fab
          small
          elevation="0"
          @click="$emit('close_modal')"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-card-title>
        <v-row :align="stateError.length ? 'start' : 'center'">
          <v-col>
            <div v-if="edit">
              {{ $t("documentation.update_document") }}
            </div>
            <div v-if="!edit">
              {{ $t("documentation.create_document") }}
            </div>
          </v-col>
          <v-col>
            <v-select
              dense
              :label="$t('table.state')"
              :items="Object.values(states)"
              :item-text="getStatusText"
              :item-value="'id'"
              hide-details="auto"
              :error-messages="stateError"
              solo
              v-model="document.id_state"
              class="text-caption"
              :menu-props="{ bottom: true, offsetY: true }"
              color="success"
              item-color="success"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start">
                  <StatusCircle
                    :status-name="item.id"
                    :type="'supply'"
                    class="pb-1"
                  />
                  <div class="elispsis">
                    {{ getStatusText(item) }}
                  </div>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center justify-content-start">
                  <StatusCircle
                    :status-name="item.id"
                    :type="'supply'"
                    class="pb-1"
                  />
                  <div class="elispsis">
                    {{ getStatusText(item) }}
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="mt-2 pt-1">
        <v-row>
          <v-col cols="12" sm="6" md="6" v-if="edit">
            <v-select
              dense
              outlined
              :label="$t('documentation.access_level')"
              :items="Object.values(accessLevels)"
              :item-value="'id'"
              v-model="document.lvl_access"
              :menu-props="{ bottom: true, offsetY: true }"
              hide-details="auto"
              color="success"
              item-color="success"
            >
              <template v-slot:item="{ item }">
                <div class="d-flex align-center justify-content-start">
                  <v-icon class="mr-2">{{ item.icon }}</v-icon>
                  <div>
                    {{ $t(item.name) }}
                  </div>
                </div>
              </template>
              <template v-slot:selection="{ item }">
                <div class="d-flex align-center justify-content-start">
                  <v-icon class="mr-2" small>{{ item.icon }}</v-icon>
                  <div>
                    {{ $t(item.name) }}
                  </div>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" v-if="edit">
            <v-select
              item-text="name"
              item-value="uuid"
              :label="$t('documentation.parent_category')"
              v-model="document.uuid_category"
              :items="selectArray"
              outlined
              dense
              hide-details="auto"
              :menu-props="{ bottom: true, offsetY: true }"
              color="success"
              item-color="success"
            >
              <template v-slot:item="{ item }">
                <v-list-item
                  v-if="item.uuid == '00000000-0000-0000-0000-000000000000'"
                >
                  <v-list-item-title>
                    <v-icon class="mr-3">{{ item.icon }}</v-icon
                    >{{ $t(`${item.name}`) }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  class="pl-10"
                  v-else-if="
                    item.uuid_parent == '00000000-0000-0000-0000-000000000000'
                  "
                >
                  <v-list-item-title>
                    <v-icon class="mr-3">{{ item.icon }}</v-icon
                    >{{ item.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class="pl-10 ml-10" v-else>
                  <v-list-item-title>
                    <v-icon class="mr-3">{{ item.icon }}</v-icon
                    >{{ item.name }}
                  </v-list-item-title>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item }">
                <div v-if="item.uuid == '00000000-0000-0000-0000-000000000000'">
                  {{ $t(`${item.name}`) }}
                </div>
                <div v-else>
                  {{ item.name }}
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="document.title"
              :label="$t('documentation.document_name')"
              :error-messages="titleError"
              outlined
              dense
              color="success"
            ></v-text-field>
          </v-col>
        </v-row>
        <div
          class="v-label mb-1"
          :style="bodyError.length > 0 ? 'color: #f4511e' : ''"
        >
          {{ $t("documentation.document_text") }}
        </div>
        <v-row>
          <v-col cols="12">
            <vue-editor
              v-model="document.body"
              id="editor"
              :class="bodyError.length > 0 ? 'error-editor' : ''"
              @text-change="editorMaxLength" ref="editor"
            />
            <span class="error_messages">{{ bodyError[0] }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              block
              class="br-10 text-transform-none"
              dark
              @click="$emit('close_modal')"
              >{{ $t("btn.cancel") }}</v-btn
            >
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-btn
              block
              class="success-bg text-white text-transform-none br-10"
              v-if="!edit"
              @click="createDocument()"
              >{{ $t("btn.save") }}</v-btn
            >
            <v-btn
              :disabled="isChanged"
              block
              class="success-bg text-white text-transform-none br-10"
              v-if="edit"
              @click="updateDocument()"
              >{{ $t("btn.save") }}</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { VueEditor } from "vue2-editor";
import DocumentsService from "@/services/request/documents/documentsService";
import EventBus from "@/events/EventBus";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import language from "@/mixins/language";
import DeportService from "@/services/request/depot/depotService";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import { mapGetters } from "vuex";
import notifications from "@/mixins/notifications";
import _ from "lodash";

const checkSize = (value) => {
  return encodeURI(value).split(/%..|./).length - 1 < 16777216 ? true : false;
};

export default {
  components: {
    VueEditor,
    StatusCircle,
  },
  mixins: [validationMixin, language, notifications],
  props: {
    item: {
      require: true,
    },
    edit: {
      require: true,
    },
    visible: {
      require: true,
    },
  },
  data: () => ({
    documentsModal: true,
    loading: false,
    categories: [],
    categoryList: [],
    document: {
      id_state: "1300",
      lvl_access: "",
    },
    documentCopy: {},
    accessLevels: [
      {
        name: "btn.all",
        icon: "mdi-account",
        id: "1000",
      },
      {
        name: "menu.owners",
        icon: "mdi-account-cowboy-hat",
        id: "1",
      },
      {
        name: "menu.admins",
        icon: "mdi-account-star",
        id: "10",
      },
      {
        name: "menu.managers",
        icon: "mdi-account-group",
        id: "20",
      },
      {
        name: "menu.employees",
        icon: "mdi-account-hard-hat",
        id: "30",
      },
      {
        name: "menu.crm",
        icon: "mdi-tooltip-account",
        id: "50",
      },
      {
        name: "menu.clients",
        icon: "mdi-clipboard-account",
        id: "40",
      },
    ],
    selectArray: [],
    states: [],
  }),
  validations() {
    return {
      document: {
        title: {
          required,
        },
        id_state: {
          required,
        },
        body: {
          checkSize,
        },
      },
    };
  },
  mounted() {
    this.getStates();
    if (this.edit) {
      this.document = { ...this.item };
      this.documentCopy = { ...this.item };
      this.getCategories();
    }
    // document.addEventListener("focusin", function (e) {
    //    
    //   if (e.target.closest(".mce-window") !== null) {
    //     e.stopImmediatePropagation();
    //   }
    // });
  },
  watch: {
    documentsModal: {
      handler() {
        this.$emit("close_modal");
      },
    },
  },
  methods: {
    editorMaxLength() {
      const quill = this.$refs.editor.quill;
      const len = quill.getLength();
      if (len > 256) {
        quill.deleteText(256, len);
      }
    },
    async getCategories() {
      try {
        this.loading = true;

        await DocumentsService.getAllCategories().then((res) => {
          this.categories = res;
          this.loading = false;
          this.getList();
        });
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    getList() {
       
      const defaultUUID = "00000000-0000-0000-0000-000000000000";

      const categoryWithParents = [];
      const parentCategoryWithChildrens = [];

      this.categories.forEach((category) => {
        category.icon = "mdi-folder";
        if (category.uuid_parent === defaultUUID) {
          category.children = [];
          parentCategoryWithChildrens.push(category);
        } else {
          categoryWithParents.push(category);
        }
      });

      parentCategoryWithChildrens.forEach((parent) => {
        categoryWithParents.forEach((category) => {
          if (category.uuid_parent === parent.uuid) {
            category.parent_name = parent.name;
            parent.children.push(category);
          }
        });
      });
      this.categoryList = parentCategoryWithChildrens;

       

      this.getSelect();
    },
    getStatusText(item) {
      return item[`${this.currentLanguage.key}_name`];
    },
    async getStates() {
      try {
        this.loading = true;

        await DeportService.getAllStates({
          type: "DOCUMENTS",
        }).then((res) => {
          this.states = res;
          this.loading = false;
        });
      } catch (e) {
        console.log(e)
        this.loading = false;
      }
    },
    getSelect() {
      const selectArray = [];
      selectArray.push({
        name: "documentation.documentation",
        uuid: "00000000-0000-0000-0000-000000000000",
        icon: "mdi-folder-multiple",
      });
      this.categoryList.forEach((category) => {
        selectArray.push(category);

        if (category.children.length) {
          category.children.forEach((cat) => {
            selectArray.push(cat);
          });
        }
      });

      this.selectArray = selectArray;
    },
    async createDocument() {
      this.$v.document.$touch();
      if (!this.$v.document.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          if (this.item.uuid) {
            formData.append("uuid_category", this.item.uuid);
          }
          formData.append("title", this.document.title);
          formData.append("body", this.document.body);
          formData.append("id_state", this.document.id_state);
          await DocumentsService.createDocument(formData).then((res) => {
            this.loading = false;
            this.documentsModal = false;
            EventBus.$emit("items-changed");
          });
        } catch (e) {
          console.log(e)
          this.loading = false;
        }
      }
    },
    async updateDocument() {
      this.$v.document.$touch();
      if (!this.$v.document.$invalid) {
        try {
          this.loading = true;
          const formData = new FormData();
          formData.append("uuid", this.document.uuid);

          const changedData = this.$getChangedData(
            this.document,
            this.documentCopy
          );
          changedData.forEach((data) => {
            formData.append(data.field, data.value);
          });
          await DocumentsService.updateDocument(formData);
          this.loading = false;
          this.documentCopy = { ...this.document };
          // //this.documentsModal = false;
          this.setSuccessNotification(this.$t('profile.successfully_updated'));
          EventBus.$emit("items-changed");
        } catch (e) {
          console.log(e)
          this.loading = false;
        }
      }
    },
  },
  computed: {
    isChanged() {
      if (!_.isEqual(this.document, this.documentCopy)) return false;
      else return true;
    },
    ...mapGetters(["currentLanguage"]),
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close_modal");
      },
    },
    titleError() {
      const errors = [];
      if (!this.$v.document.title.$dirty) {
        return errors;
      }
      !this.$v.document.title.required &&
        errors.push(
          this.language.isRequired(this.$t("documentation.document_name"))
        );
      return errors;
    },
    stateError() {
      const errors = [];
      if (!this.$v.document.id_state.$dirty) {
        return errors;
      }
      !this.$v.document.id_state.required &&
        errors.push(this.language.isRequired("Статус"));
      return errors;
    },
    bodyError() {
      const errors = [];
      if (!this.$v.document.body.$dirty) {
        return errors;
      }
      !this.$v.document.body.checkSize &&
        errors.push("Розмір документа не повинен перевищувати 16МБ");
      return errors;
    },
  },
};
</script>

<style>
body {
  margin: unset;
}

#editor {
  height: 300px;
  overflow-y: scroll;
}

.error_messages {
  font-size: 12px;
  color: #f4511e;
}

.error-editor {
  border: 2px solid #f4511e;
  border-radius: 5px;
}
</style>
